#header {
  width: 100%;
  min-height: 44px;
  z-index: 5;
  background-color: white;
  border-bottom: 1px solid #dadada;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-wrap: wrap; }
  @media only screen and (max-width: 799px) {
    #header {
      padding-left: 16px;
      padding-right: 16px; } }
  @media only screen and (min-width: 800px) {
    #header {
      padding-left: 32px;
      padding-right: 32px; } }
  #header h1 {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 500;
    font-size: 17px;
    margin-right: 16px; }
  #header .noteLink {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 15px;
    color: #333;
    text-decoration: none;
    margin-left: 16px; }
    #header .noteLink:hover {
      text-decoration: underline; }
    #header .noteLink:visited {
      color: inherit; }
    @media only screen and (max-width: 799px) {
      #header .noteLink {
        display: none; } }
  #header .userState {
    margin-left: auto; }
    #header .userState .anonymousNotice {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 15px;
      color: #0a84ff; }
      #header .userState .anonymousNotice a {
        color: inherit; }
    #header .userState .identity {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 15px;
      font-weight: 300;
      color: #999; }
      @media only screen and (max-width: 799px) {
        #header .userState .identity {
          display: none; } }
